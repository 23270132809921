import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Testing hosting stuff</h1>
      <p>Happy this is working</p>
    </div>
  );
}

export default App;
